.App {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

.content {
  background-color: aquamarine;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  padding: 0;
  /* overflow-y: hidden; */
}

/* navbar */

.navbar {
  display: flex;
  padding: 1rem;
  background-color: #333;
  color: #fff;
  margin: auto;
}


/* google sign  */

.ehe {
  display: flex;
  align-items: center;
  margin-left: 20%;
}

.signinpage {
  background-image: url(./hello/undraw_secure_login_pdn4\ \(1\).svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 200px;
}

.google-signin {
  color: #ffffff;
  width: 100%;
  height: 100vh;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* drag and drop */

.draganddrop {
  margin: auto;
}

.drop-zone {
  width: 700px;
  height: 400px;
  border: 4px dashed #357ae8;
  margin: auto;
}

.dragging {
  opacity: 0.5;
}

.head {
  text-align: center;
  font-size: 50px;
}

.file-input {
  display: block;
  margin: auto;
  margin-top: 5%;
}

.text {
  text-align: center;
  font-size: 100px;
  padding-top: 100px;
  color: white;
}

.file-name {
  text-align: center;
}

.logout-button {
  display: block;
  background-color: #ff0000;
  /* Red background color */
  color: #fff;
  /* White text color */
  padding: 10px 15px;
  /* Padding around the button */
  border: none;
  /* No border */
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
  /* Show pointer cursor on hover */
  margin-top: 30px;
  margin-bottom: 30px;
}

.logout-button:hover {
  background-color: black;
  /* Darker red on hover */
}

.copy {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: black;
  color: white;
  border: #fff;
  border-radius: 5px;
}

.copy:hover {
  border: white;
  border-radius: 5px;
  border-width: 2px;
  background-color: white;
  color: black;
  font-weight: 700;

}


/*####### MOBILE VIEW ##########*/

@media screen and (max-width:1500px) {

  .signinpage {
    background-image: none;
  }

  .google-signin {
    color: #ffffff;
    width: 100%;
    height: 100vh;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drop-zone {
    width: 500px;
    height: 300px;
    border: 3.5px dashed #357ae8;
    padding: 20px;
    margin: auto;
  }

  .text {
    text-align: center;
    font-size: 55px;
    padding-top: 70px;
    color: white;
  }
}


@media screen and (max-width:1300px) {

  .drop-zone {
    width: 400px;
    height: 300px;
    border: 3px dashed #ccc;
    padding: 20px;
    margin: auto;
  }

  .text {
    text-align: center;
    font-size: 50px;
    padding-top: 70px;
    color: white;
  }
}

@media screen and (max-width:400px) {

  .signinpage {
    width: 300px;
    height: 150px;
    margin: 0;
    margin-top: 30%;
  }

  .google-signin {
    color: #ffffff;
    width: 20%;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0;
    margin-left: 28%;
  }

  .head {
    display: none;
  }

  .drop-zone {
    display: none;
  }

  .file-input {
    display: block;
    margin: auto;
    margin-top: 20%;
    padding: 10px 15px;
  }

  .text {
    text-align: center;
    font-size: 30px;
    padding-top: 40px;
    color: white;
  }
}